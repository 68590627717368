import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import AlphaVideo from "~components/AlphaVideo";
import Layout from "~components/Layout";
import RotatingButtonLink from "~components/RotatingButtonLink";
import SEO from "~components/SEO";

class StartPageComponent extends Component {
  state = {
    backgroundColor: `bg-pink`,
    countdown: false,
    countdownValue: `3`,
    preparingCountdown: false
  };

  timeouts = [];

  componentDidMount() {
    this.props.appContext.setActivePath(`/start`);
  }

  componentWillUnmount() {
    if (this.timeouts.length) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  }

  //

  startCountdown = () => {
    const { appContext, navigate } = this.props;

    const initialTimeout = 301;

    this.setState({
      preparingCountdown: true
    });

    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          backgroundColor: `bg-burntish`,
          countdown: true
        });
      }, initialTimeout)
    );

    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          backgroundColor: `bg-burnt`,
          countdownValue: `2`
        });
      }, initialTimeout + 1000)
    );

    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          backgroundColor: `bg-burnter`,
          countdownValue: `1`
        });
      }, initialTimeout + 2000)
    );

    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          backgroundColor: `bg-burntest`,
          countdownValue: `GO`
        });
      }, initialTimeout + 3000)
    );

    this.timeouts.push(
      setTimeout(() => {
        appContext.setQuizState(0);

        navigate(`/quiz`);
      }, initialTimeout + 3300)
    );
  };

  //

  render() {
    const {
      backgroundColor,
      countdown,
      countdownValue,
      preparingCountdown
    } = this.state;
    const { frontmatter } = this.props;

    return (
      <Div100vh className="overflow-hidden">
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className={`start-page w-full h-full relative ${backgroundColor}`}
          outerClassName="w-full h-full relative"
          footerClass="black"
          footerType="desktop-only"
          headerClass="black"
        >
          <div className="w-full h-full relative flex flex-col items-center justify-center sm:justify-between sm:pt-12 sm:pb-8 overflow-hidden">
            <div className="grid sm:flex sm:flex-1 sm:self-stretch">
              <article className="start-page__inner grid-end-8 md:grid-end-10 sm:w-full grid-start-3 md:grid-start-2 relative flex flex-col items-center justify-center z-10">
                {(!countdown && (
                  <div
                    className={`${
                      preparingCountdown ? `countdown-hide` : ``
                    } countdown-hideable`}
                  >
                    <h1
                      className="animation-appear-from-right f2 text-center"
                      style={{
                        animationDelay: `100ms`
                      }}
                    >
                      {frontmatter.heading}
                    </h1>

                    <p
                      className="animation-appear-from-right mt-8 xs:mt-8 b2 text-center"
                      style={{ animationDelay: `200ms` }}
                    >
                      {frontmatter.content}
                    </p>
                  </div>
                )) || (
                  <h1 className="animation-appear big-number text-center text-white">
                    {countdownValue}
                  </h1>
                )}
              </article>

              <>
                {!preparingCountdown && (
                  <section
                    className="start-page__video animation-appear grid-end-12 flex items-center justify-center"
                    style={{ animationDelay: `300ms` }}
                  >
                    <div className="w-full relative">
                      <AlphaVideo src="/uploads/alpha-video-2.mp4" />
                    </div>
                  </section>
                )}

                {countdown && (
                  <section
                    className="start-page__video animation-appear grid-end-12 xs:w-full xs:absolute flex items-center justify-center"
                    style={{ animationDelay: `300ms` }}
                  >
                    <div className="w-full relative">
                      <AlphaVideo src="/uploads/alpha-video-3.mp4" />
                    </div>
                  </section>
                )}
              </>
            </div>

            {!preparingCountdown && (
              <div
                className="animation-appear w-full absolute xs:relative right-0 bottom-0 left-0 z-20 flex items-center justify-center pb-12 xs:p-0 xs:pt-4 whitespace-no-wrap"
                style={{ animationDelay: `300ms` }}
              >
                <RotatingButtonLink
                  className="infinispin"
                  onClick={this.startCountdown}
                  text="I'm ready"
                />
              </div>
            )}
          </div>
        </Layout>
      </Div100vh>
    );
  }
}

StartPageComponent.defaultProps = {
  frontmatter: {
    title: ``,
    heading: ``,
    content: ``,
    seoDescription: ``,
    seoKeywords: ``
  }
};

StartPageComponent.propTypes = {
  appContext: PropTypes.shape({
    setActivePath: PropTypes.func.isRequired,
    setQuizState: PropTypes.func.isRequired
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    heading: PropTypes.string,
    content: PropTypes.string,
    seoDescription: PropTypes.string,
    seoKeywords: PropTypes.string
  }),
  navigate: PropTypes.func.isRequired
};

//

const StartPage = ({ data, navigate }) => {
  const appContext = useContext(AppContext);
  const { frontmatter } = data.markdownRemark;

  return (
    <StartPageComponent
      appContext={appContext}
      frontmatter={frontmatter}
      navigate={navigate}
    />
  );
};

export default StartPage;

StartPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({}).isRequired
    })
  }).isRequired,
  navigate: PropTypes.func.isRequired
};

export const query = graphql`
  query StartPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        content
        seoDescription
        seoKeywords
      }
    }
  }
`;
